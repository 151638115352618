import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Featured from '~components/directory/featured'
import Search from '~components/search/search'

const DirectoryPage = props => {
  const { section, locale, type } = props.pageContext
  const { location } = props
  const directoryPage = props.data.directoryPage.nodes.find(({ node_locale }) => node_locale === locale)
  const settings = props.data.settings.nodes.find(({ node_locale }) => node_locale === locale)

  let recommendedSearchTerms = []
  switch (type) {
    case 'Activity':
      recommendedSearchTerms = settings.activityDirectoryRecommendedSearchTerms
      break
    case 'Video':
      recommendedSearchTerms = settings.videoDirectoryRecommendedSearchTerms
      break
    default:
      break
  }

  return (
    <Layout locale={locale} location={location} section={section} containsSearch>
      <SEO
        lang={locale}
        title={directoryPage?.metaTitle || directoryPage.title}
        description={directoryPage?.metaDescription}
      />
      <div>
        <div className='max-w-page py-8'>
          <Featured title={directoryPage.featuredSectionTitle} type={type} />
        </div>
        <hr className='accent-rule' aria-hidden />
        <div className='max-w-page py-8'>
          <div className='headline-1'>{directoryPage.title}</div>
        </div>
        <hr className='accent-rule' aria-hidden />
      </div>
      <div className='max-w-page mb-24'>
        <Search type={type} displayStyle='card' recommendedSearchTerms={recommendedSearchTerms} />
      </div>
    </Layout>
  )
}

export default DirectoryPage

export const directoryQuery = graphql`
  query directoryQuery($slug: String!) {
    directoryPage: allContentfulActivityOrVideoDirectory(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        title
        metaTitle
        metaDescription
        featuredSectionTitle
      }
    }
    settings: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        activityDirectoryRecommendedSearchTerms {
          name
          slug
        }
        videoDirectoryRecommendedSearchTerms {
          name
          slug
        }
      }
    }
  }
`
