import React from 'react'
import { useLocation } from '@reach/router'
import qs from 'query-string'

import { useMediaCardData } from '~hooks'

import { MediaCard } from '~components/shared'

const Featured = ({ title, type }) => {
  const _ = require(`lodash`)
  const location = useLocation()
  const articles = useMediaCardData()

  const { search } = location
  const searchParams = qs.parse(search)
  let { ages = undefined } = searchParams

  // get and shuffle an array of featured articles
  const featuredArticles =
    ages !== undefined
      ? articles
          .filter(article => article.type === type && article.featured === true && article.slug !== 'toxic-stress-break-the-cycle')
          .filter(article => article.ages.some(({ name }) => name === ages))
      : articles.filter(article => article.type === type && article.featured === true && article.slug !== 'toxic-stress-break-the-cycle')
  const shuffledFeaturedArticles = _.shuffle(featuredArticles)

  const toxicStress = _.filter(articles, {'slug': 'toxic-stress-break-the-cycle'})
  console.log('toxic', shuffledFeaturedArticles)

  return (
    <>
      <div className='headline-section mb-16'>
        <div>{title}</div>
        <hr aria-hidden />
      </div>
      <div className='flex flex-col md:flex-row flex-wrap lg:flex-no-wrap -mx-4'>
        <div key={toxicStress[0].id} className='flex md:w-1/2 lg:w-1/4 px-4 mb-8 lg:mb-0' id='kenny'>
          <MediaCard type={toxicStress[0].type} image={toxicStress[0].image} title={toxicStress[0].title} tags={toxicStress[0].tags} url={toxicStress[0].url} />
        </div>
        {shuffledFeaturedArticles.slice(0, 3).map(({ id, image, title, tags, url }) => (
          <div key={id} className='flex md:w-1/2 lg:w-1/4 px-4 mb-8 lg:mb-0'>
            <MediaCard type={type} image={image} title={title} tags={tags} url={url} />
          </div>
        ))}
      </div>
    </>
  )
}

export default Featured
